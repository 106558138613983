import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import Footer from "../components/Footer"
import { discordUrl, appstoreUrl } from "../constants"
import { OutboundLink } from "gatsby-plugin-google-gtag"

import TwitchToTiktokMOV from "../images/twitch-to-tiktok.mp4"
import StarSVG from "../images/star.svg"

const Home = () => {
  return (
    <div className="bg-white center w-100">
      <Seo
        title="Share Twitch clips and track your channel growth | StreamKit"
        description="StreamKit makes it easy to edit Twitch clips, share them to TikTok and Instagram, and track your channel's growth."
      />
      <section className="pv5 ph3 w-100 relative bg-white">
        <div
          className="absolute top-0 right-0 left-0 h-100 bg-primary"
          style={{ zIndex: 2, bottom: "120px", transform: "skewY(-6deg)" }}
        >
        </div>
        <div
          className="absolute top-0 right-0 left-0 bg-primary"
          style={{ height: "240px", zIndex: 3 }}
        >
          <div className="ph4 ph0-l justify-end w-100 center items-center flex flex-wrap pt3" style={{maxWidth: "860px", gap: "30px"}}>
            <a href="/blog" className="no-underline light-gray b f4 hover-white pointer" rel="noreferrer">Blog</a>
            <a href={discordUrl} target="_blank" className="no-underline light-gray b f4 hover-white pointer" rel="noreferrer">Discord</a>
            <a href="/twitch-clip-download" className="dn dib-l no-underline b f5 pv2 ph3 bg-white br4 near-black grow pointer">Download Twitch Clips</a>
          </div>
        </div>
        <div className="mt2-l mt4 container mb3 flex flex-row items-center justify-center center" style={{ maxWidth: "75rem" }}>
          <div style={{ zIndex: 999 }} className="mr5-l mr0 text header">
            <div className="flex flex-row items-center mb3" style={{ maxWidth: "35rem" }}>
              <StaticImage
                imgClassName="br2"
                backgroundColor="transparent"
                placeholder="none"
                height={35}
                width={35}
                src="../images/180.png"
                alt="icon"
                quality={100}
              />
              <div className="f2 white ml1">Stream</div>
              <div className="f2 white b">Kit</div>
            </div>
            <div className="mb3 b white" style={{ fontSize: "3.5rem" }}>
              Promote and Grow your Twitch Channel
            </div>
            <div className="light-gray lh-copy f4 mb3">
              StreamKit makes it easy to share Twitch clips to TikTok and Instagram, track 
              your channel's growth, and identify your biggest fans.
            </div>
            <OutboundLink href={appstoreUrl} target="_blank" className="no-underline" rel="noreferrer">
              <div className="grow pointer bg-white br3 pa3 flex flex-row items-center cta mb0-l mb4">
                <StaticImage
                  backgroundColor="transparent"
                  placeholder="none"
                  height={30}
                  width={30}
                  src="../images/apple-logo-black.png"
                  alt="app store"
                  quality={100}
                />
                <div className="black b f4 ph2">Get started for FREE!</div>
              </div>
            </OutboundLink>
          </div>
          <div style={{ zIndex: 999 }} className="ml5-l ml0 image">
            <StaticImage
              imgClassName="shadow"
              backgroundColor="transparent"
              placeholder="none"
              style={{maxWidth: "330px", width: "100%"}}
              className="h-auto"
              src="../images/hero-image.png"
              alt="share clip to TikTok"
              quality={100}
            />
          </div>
        </div>
      </section>

      <section className="pv5 ph3 w-100 relative bg-white" style={{ zIndex: 1 }}>
        <div className="flex flex-column items-center justify-center center" style={{ maxWidth: "75rem" }}>
          <div className="f2 f1-l tc mb3 b background-color mt4 mt5-l" style={{maxWidth: "600px"}}>
            Edit your <b className="primary">Twitch Clips</b> in seconds and post directly to TikTok and Instagram
          </div>
          <div className="w-60-l w-100">
            <video playsInline autoPlay muted loop width="100%" height="auto">
              <source src={TwitchToTiktokMOV} type="video/mp4" />
            </video>
          </div>
        </div>
      </section>
      <section className="bg-near-white w-100 pv5 ph3">
        <div className="container container-reverse flex center flex-row items-center justify-center" style={{ maxWidth: "75rem" }}>
          <div className="mr5-l mr0 image">
            <StaticImage
              imgClassName="shadow"
              backgroundColor="transparent"
              placeholder="none"
              style={{maxWidth: "330px", width: "100%"}}
              className="h-auto"
              src="../images/styles.png"
              alt="Twitch follower engagement"
              quality={100}
            />
          </div>
          <div className="ml5-l ml0 mb4 mb0-l text">
            <div className="b f2 background-color mb3">Tons of different styles to make your content <b className="primary">stand out</b></div>
            <div className="f4 mid-gray lh-copy">Choose from Blur, Split cam, Freecam, and more. StreamKit's video templates will get you up and running in no time.</div>
          </div>
        </div>
      </section>
      <section className="bg-primary pa3" style={{ marginTop: "-32px" }}>
        <div className="flex flex-row justify-around w4 center">
          <img alt="star" src={StarSVG} />
          <img alt="star" src={StarSVG} />
          <img alt="star" src={StarSVG} />
          <img alt="star" src={StarSVG} />
          <img alt="star" src={StarSVG} />
        </div>
        <div className="flex flex-column justify-center tc center lh-copy mw7 white mt3" style={{ maxWidth: "400px" }}>
          <div className="mb3 f3"><mark>"Great for streamers of any size!</mark> Highly recommend this tool to anyone getting into streaming."</div>
          <div>- 5 star review on the App Store</div>
        </div>
      </section>
      <section
        className="bg-white w-100 overview-section"
        style={{ padding: "4rem 1rem calc(4rem + 100px)" }}
      >
        <div className="container container-reverse flex center items-center justify-center" style={{ maxWidth: "75rem" }}> 
          <div className="dn-l db overflow-hidden vw-100">
            <StaticImage
              backgroundColor="transparent"
              placeholder="none"
              style={{ maxWidth: "450px", overflow: "hidden" }}
              className="h-auto"
              src="../images/overview-3.png"
              alt="twitch channel stats"
              quality={100}
            />
          </div>
          <div className="db-l dn">
            <div className="relative image" style={{ width: "490px" }}>
              <div
                className="absolute shadow"
                style={{
                  top: "100px",
                  bottom: "-100px",
                  right: "-240px",
                  left: "240px",
                  width: "250px",
                }}
              >
                <StaticImage
                  imgClassName="br4 shadow"
                  backgroundColor="transparent"
                  placeholder="none"
                  style={{ width: "250px" }}
                  className="h-auto"
                  src="../images/overview.png"
                  alt="twitch channel stats"
                  quality={100}
                />
              </div>
              <div
                className="absolute shadow"
                style={{
                  top: "50px",
                  bottom: "-50px",
                  right: "-120px",
                  left: "120px",
                  width: "250px",
                }}
              >
                <StaticImage
                  imgClassName="br4 shadow"
                  backgroundColor="transparent"
                  placeholder="none"
                  style={{ width: "250px" }}
                  className="h-auto"
                  src="../images/audience.png"
                  alt="twitch channel unfollowers"
                  quality={100}
                />
              </div>
              <div className="shadow" style={{ width: "250px" }}>
                <StaticImage
                  imgClassName="br4 shadow"
                  backgroundColor="transparent"
                  placeholder="none"
                  style={{ width: "250px" }}
                  className="h-auto"
                  src="../images/graph.png"
                  alt="twitch channel follower graph"
                  quality={100}
                />
              </div>
            </div>
          </div>

          <div className="text ml5-l ml0 mb0-l mb4">
            <div className="f2 b background-color mb3">Track progress and goals for <b className="primary">followers and views</b></div>
            <div className="f4 mid-gray lh-copy">Track day to day growth of your Twitch channel, and see who follows and views your channel.</div>
          </div>
        </div>
      </section>
      <section className="bg-primary w-100 pv5 ph3">
        <div className="flex flex-column center items-center justify-center" style={{ maxWidth: "75rem" }}>
          <div className="f2 white mb3">Seen enough?</div>
          <OutboundLink href={appstoreUrl} target="_blank" className="no-underline" rel="noreferrer">
            <div className="mb3 grow pointer bg-white br3 pa3 flex flex-row items-center" style={{ width: "fit-content" }}>
              <StaticImage
                backgroundColor="transparent"
                placeholder="none"
                height={30}
                width={30}
                src="../images/apple-logo-black.png"
                alt="app store"
                quality={100}
              />
              <div className="background-color b f4 ph2">Get started for FREE!</div>
            </div>
          </OutboundLink>
          <div className="white tc f4 lh-copy" style={{ maxWidth: "400px" }}>
            If you'd like to learn more, have 
            questions, or just want to chat
            <a href={discordUrl} target="_blank" className="pointer pa1 b br2 white" rel="noreferrer">
              you can click here to join our discord
            </a><br /><br />I'm 
            in there all the time
            <div className="f6 white tc">(seriously)</div>
          </div>
        </div>
      </section>
      <section className="center bg-white w-100 pv5 ph3" style={{ maxWidth: "75rem" }}>
        <Footer />
      </section>
    </div>
  )
}

export default Home