import React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"

import { discordUrl } from "../constants"

const Footer = ({ white }) => (
  <footer>
    <div className="flex flex-column center">
    <div className="flex flex-row items-center mb2">
      <a href="/" className="flex flex-row items-center no-underline">
        <StaticImage
          imgClassName="br2"
          backgroundColor="transparent"
          placeholder="none"
          height={35}
          width={35}
          src="../images/180.png"
          alt="icon"
          quality={100}
        />
        <div className={`f3 ${white ? 'white' : 'background-color'} ml1`}>Stream</div>
        <div className={`f3 ${white ? 'white' : 'background-color'} b`}>Kit</div>
       </a>
    </div>
    <div className="flex flex-row justify-between w-100">
      <div className={`f6 ${white ? 'white' : 'gray'}`}>Copyright © 2021 Lofi Labs, LLC</div>
      <div className="flex flex-row-ns flex-wrap justify-end" style={{ gap: "1rem" }}>
        <a target="_blank" rel="noreferrer" href={discordUrl} className={`f6 pointer underline ${white ? 'white' : 'gray'} mr3`}>Discord</a>
        <a href="/privacy" className={`f6 pointer underline ${white ? 'white' : 'gray'} mr3`}>Privacy Policy</a>
        <a href="/terms" className={`f6 pointer underline ${white ? 'white' : 'gray'}`}>Terms & Conditions</a>
      </div>
    </div>
  </div>
  </footer>
)

Footer.propTypes = {
  white: PropTypes.bool,
}

Footer.defaultProps = {
  white: false,
}

export default Footer
